import React from 'react';
import {ColorBox} from './color-box';
import styled from 'styled-components';
import {ModuleInfo} from '../../models/module-info';
import {Markdown} from '../shared/markdown';
import {device} from '../../models/devices';

const Container = styled.section`
  border-bottom: 1px solid #e1efff;
  padding-bottom: 90px;
  padding-top: 120px;

  @media screen and ${device.tablet} {
    padding: 80px 0 0;
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-gap: 30px;
  grid-auto-rows: auto;
  align-items: end;
  margin: 0 auto 30px;


  @media screen and ${device.tablet} {
    padding: 0 24px;
  }
`;

const Header = styled.h2`
  text-align: center;
  color: #4231A9;
  font-size: 48px;
  font-weight: 500;
  margin: 0;
  
  @media screen and ${device.tablet} {
    font-size: 24px;
    text-align: left;
    margin-bottom: 32px;
    padding: 0 24px;
  }
`;

const HR = styled.hr`
  margin-top: 56px;
  margin-bottom: 50px;
  border-color: #d8d8d8;
  border-width: 0;
  border-bottom-width: 1px;
`;

export const Colors = (props: {
  title: string;
  primarySecondaryColors: Array<{
    title?: string;
    color: string;
    isPrimary: boolean;
  }>;
  tertiaryColors: Array<{
    title?: string;
    color: string;
  }>;
  otherColors: Array<{
    title?: string;
    color: string;
  }>;
  moduleColorsTitle: string;
  modules: ModuleInfo[];
}): JSX.Element => {
  return (
    <Container>
      <Header><Markdown>{props.title}</Markdown></Header>
      <div style={{maxWidth: 1170, margin: '0 auto'}}>
        <List>
          {
            props.primarySecondaryColors
              .filter(({isPrimary}) => isPrimary)
              .map(({color, title}, index) => (
                <ColorBox color={color} title={title} key={index} />
              ))
          }
        </List>
        <List>
          {
            props.primarySecondaryColors
              .filter(({isPrimary}) => !isPrimary)
              .map(({color, title}, index) => (
                <ColorBox color={color} title={title} key={index} />
              ))
          }
        </List>
        <HR/>
        <List>
          {
            props.tertiaryColors
              .map(({color, title}, index) => (
                <ColorBox color={color} title={title} key={index} />
              ))
          }
        </List>
        <HR/>
        <List>
          {
            props.modules
              .sort((a, b) => a.position - b.position)
              .map((module, index) => (
                <div key={index}>
                  <ColorBox color={module.color} title={index === 0 ? props.moduleColorsTitle : ''} />
                  <div style={{fontSize: 14, fontWeight: 600, margin: '15px 0'}}>{module.name}</div>
                  <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <img src={module.icon} alt={module.altText} style={{width: 32, height: 32}} />
                    <div style={{width: 1, height: 9, background: '#e6ecfb', margin: '0 19px'}}/>
                    <img style={{filter: 'grayscale(100%)', width: 32, height: 32}} src={module.icon} alt={module.altText} />
                  </div>
                </div>
              ))
          }
        </List>
        <HR/>
        <List>
          {
            props.otherColors
              .map(({color, title}, index) => (
                <ColorBox color={color} title={title} key={index} />
              ))
          }
        </List>
      </div>
    </Container>
  );
};
