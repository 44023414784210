import {contrast} from './contrast';

export function contrastColor(backgroundColor: string | undefined | null, color1: string, color2: string): string {
  const contrast1 = contrast(backgroundColor ?? '#ffffff', color1);
  const contrast2 = contrast(backgroundColor ?? '#ffffff', color2);
  let resultColor: string;
  if (contrast1 === undefined) {
    resultColor = color2;
  } else if (contrast2 === undefined) {
    resultColor = color1;
  } else if (contrast1 > contrast2) {
    resultColor = color1;
  } else {
    resultColor = color2;
  }
  return resultColor;
}
