import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import BorderRight from '../../images/arrow-border.svg';
import BorderDown from '../../images/arrow-down-border.svg';
import {Markdown} from '../shared/markdown';
import {device} from '../../models/devices';

const Container = styled.section`
  margin-bottom: 120px;
  padding-top: 120px;

  @media screen and ${device.tablet} {
    padding: 80px 24px 0;
    margin-bottom: 80px;
  }
`;

const Header = styled.h2`
  text-align: center;
  color: #4231A9;
  font-size: 48px;
  font-weight: 500;
  margin: 0 0 87px;

  @media screen and ${device.tablet} {
    text-align: left;
    font-size: 24px;
    margin-bottom: 32px;
  }
`;

const Grid = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 56px 30px;
  max-width: 1170px;
  margin: 0 auto;
  
  @media screen and ${device.tablet} {
    grid-row-gap: 72px;
    grid-template-columns: 1fr;
  }
`;

const RightSide = styled.div`
  grid-area: rightSide;
  font-size: 14px;
  font-weight: 400;
  color: #7B85A0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 45px;
  padding-right: 13px;
  
  &.full-width {
    grid-area: 2 / 1 / 2 / 3;
    display: block;
    padding: 0;
  }
`;

const Strip = styled.div<{color: string}>`
  width: 6px;
  height: calc(100% + 29px + 16px);
  background: linear-gradient(0deg, rgba(79,64,171,0) 0%, ${props => props.color} 50%, rgba(79,64,171,0) 100%);
  grid-area: 2 / 1 / 3 / 1;
  transform: translateY(-16px);
`;

const LeftSideList = styled.ol`
  grid-area: leftSide;
  background-image: url(${BorderRight});
  background-repeat: no-repeat;
  background-position: right center;
  font-weight: 500;
  
  @media screen and ${device.tablet} {
    padding-bottom: 60px;
    margin-bottom: 15px;
    background-image: url(${BorderDown});
    background-position: bottom left;
  }
`;

const CardTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 13px;

  @media screen and ${device.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Box = styled.div<{color: string;}>`
  border-radius: 16px;
  background: white;
  box-shadow: 3px 10px 36px rgba(225, 239, 255, 0.50825);
  padding: 22px 32px;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
          'header header'
          'leftSide rightSide';
  position: relative;
  overflow: hidden;
  grid-area: 2 / 1 / 3 / 1;

  &:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 100%;
    background: ${props => props.color};
    position: absolute;
  }
  
  ol {
    list-style-type: decimal;
    padding-left: 1em;
    font-size: 14px;
    line-height: 1.7em;
  }

  b, strong {
    font-weight: 500;
    color: black;
  }
  
  header {
    grid-area: header;
    color: #7B85A0;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  
  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-areas: "header" "leftSide" "rightSide";
    
    header {
      font-size: 14px;
      line-height: 1.5em;
    }
    
    ${RightSide} {
      justify-content: flex-start;
      padding: 0;
    }
  }
`;

export const DosDontsComponent = (props: {
  title: string;
  cards: Array<{
    color: string;
    title: string;
    header: string;
    rightSideText: string;
    orderedList?: Array<{
      text: string;
      position: number;
    }>;
  }>;
}): JSX.Element => {
  return (
    <Container>
      <Header><Markdown>{props.title}</Markdown></Header>
      <Grid>
        {
          props.cards.map((card, index) => (
            <div key={index} style={{display: 'grid', gridTemplateRows: 'auto auto'}}>
              <CardTitle>{card.title}</CardTitle>
              <Strip className={'desktop-only'} color={card.color} />
              <Box color={card.color}>
                {card.header?.length > 0 && <header><ReactMarkdown>{card.header}</ReactMarkdown></header>}
                {card.orderedList && (
                  <LeftSideList>
                    {
                      card.orderedList
                        ?.sort((a, b) => a.position - b.position)
                        .map((listItem, j) => (
                          <li key={j}>{listItem.text}</li>
                        ))
                    }
                  </LeftSideList>
                )}
                <RightSide className={card.orderedList ? '' : 'full-width'}>
                  <ReactMarkdown>{card.rightSideText}</ReactMarkdown>
                </RightSide>
              </Box>
            </div>
          ))
        }
      </Grid>
    </Container>
  );
};
