import React from 'react';
import styled from 'styled-components';
import {contrastColor} from '../../helpers/contrast-text-color';

const Box = styled.div<{
  backgroundColor: string;
  textColor: string;
}>`
  width: 170px;
  height: 170px;
  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  color: ${props => props.textColor};
  font-weight: 500;
  box-sizing: border-box;
  padding-left: 16px;
  padding-bottom: 27px;
`;

export const ColorBox = (props: {
  title?: string;
  color: string;
}): JSX.Element => {

  const textColor = contrastColor(props.color, '#ffffff', '#7B85A0');

  return (
    <div>
      {props.title && <h3 style={{
        color: '#7B85A0',
        fontSize: 16,
        fontWeight: 500,
        margin: 0,
        marginBottom: 9
      }}>{props.title}</h3>}
      <Box backgroundColor={props.color} textColor={textColor}>
        <header style={{fontSize: 14}}>Hex:</header>
        <span style={{fontSize: 20, textTransform: 'uppercase'}}>{props.color}</span>
      </Box>
    </div>
  );
};
