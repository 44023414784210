import React from 'react';
import {BasePageData, BasePageFooter, BasePageHeader} from '../helpers/base-page';
import {graphql} from 'gatsby';
import './styles.scss';
import {TitleSection} from '../components/brandings/title-section';
import {MarkdownRemark} from '../models/markdown-remark';
import {DosDontsComponent} from '../components/brandings/dos-donts';
import {Logos} from '../components/brandings/logos';
import {Colors} from '../components/brandings/colors';
import {BackgroundFigure} from '../components/shared/background-figure';
import {RegularSectionModel} from '../models/regular-section-model';
import { RegularSection } from '../components/shared/regular-section';

interface BrandingPageData extends BasePageData {
  pageData: MarkdownRemark<{
    seoTitle: string;
    title: string;
    heroImage: string;
    altText: string;
    subText: string;
    subSections: Array<{
      type: 'dosAndDonts';
      title: string;
      cardListItems: Array<{
        color: string;
        title: string;
        header: string;
        rightSideText: string;
        orderedList?: Array<{
          text: string;
          position: number;
        }>;
      }>;
    } | {
      type: 'LogoTypes';
      title: string;
      logoListItems: Array<{
        title: string;
        link: string;
        altText: string;
      }>;
    } | {
      type: 'brandColors';
      title: string;
      primarySecondaryColors: Array<{
        title?: string;
        color: string;
        isPrimary: boolean;
      }>;
      tertiaryColors: Array<{
        title?: string;
        color: string;
      }>;
      otherColors: Array<{
        title?: string;
        color: string;
      }>;
      moduleColorsTitle: string;
    } | RegularSectionModel>
  }>;
}

const BrandingPage = ({data}: {data: BrandingPageData}): JSX.Element => {

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.pageData.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
      />
      <TitleSection
        title={data.pageData.edges[0].node.frontmatter.title}
        text={data.pageData.edges[0].node.frontmatter.subText}
        image={data.pageData.edges[0].node.frontmatter.heroImage}
        alt={data.pageData.edges[0].node.frontmatter.altText}
      />
      {
        data.pageData.edges[0].node.frontmatter.subSections.map((section, index) => {
          switch (section.type) {
            case 'dosAndDonts': {
              return (
                <DosDontsComponent
                  title={section.title}
                  cards={section.cardListItems}
                  key={index}
                />
              );
            }
            case 'LogoTypes': {
              return (
                <Logos key={index} title={section.title} logos={section.logoListItems} />
              );
            }
            case 'brandColors': {
              return (
                <Colors
                  key={index}
                  title={section.title}
                  primarySecondaryColors={section.primarySecondaryColors}
                  moduleColorsTitle={section.moduleColorsTitle}
                  otherColors={section.otherColors}
                  tertiaryColors={section.tertiaryColors}
                  modules={data.modules.edges.map(i => i.node.frontmatter)}
                />
              );
            }
            case 'regular': {
              return <RegularSection key={index} {...section.section} />;
            }
            default:
              return null;
          }
        })
      }
      <div className={'desktop-only'}>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 76, height: 203}}
          position={{left: 0, top: 1120}}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 23, height: 46}}
          position={{right: 0, top: 745}}
          style={{
            zIndex: 0,
            borderRadius: 23,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <BackgroundFigure
          type={'dots'}
          color={''}
          size={{width: 869, height: 739}}
          position={{left: 'calc(50% - 700px)', top: 1360}}
        />
      </div>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default BrandingPage;

export const pageQuery = graphql`
  {
    ...BaseData
    pageData: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/branding.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            heroImage
            altText
            subText
            subSections {
              type
              title
              logoListItems {
               title
               link
               altText
              }
              section {
                alignment
                label
                color
                background
                title
                image
                altText
                subText
              }
              primarySecondaryColors {
                title
                color
                isPrimary
              }
              tertiaryColors {
                title
                color
              }
              moduleColorsTitle
              otherColors {
                title
                color
              }
              cardListItems {
                color
                title
                header
                rightSideText
                orderedList {
                  text
                  position
                }
              }
            }
          }
        }
      }
    }
  }
`;
