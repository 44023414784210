import React from 'react';
import styled from 'styled-components';
import {device} from '../../models/devices';
import {Markdown} from '../shared/markdown';

const Container = styled.section`
  border-bottom: 1px solid #e1efff;
  padding-bottom: 120px;
  padding-top: 120px;

  @media screen and ${device.tablet} {
    padding-top: 80px;
    padding-bottom: 24px;
  }
`;

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  max-width: 1170px;
  margin: 0 auto;
  clip-path: polygon(
    0 0,
    calc(100% - 1px) 0,
    calc(100% - 1px) calc(100% - 2px),
    0 calc(100% - 2px),
    0 0
  );
`;

const Header = styled.h2`
  text-align: center;
  color: #4231A9;
  font-size: 48px;
  font-weight: 500;
  margin: 0 0 55px;

  @media screen and (max-width: 970px) {
    font-size: 24px;
    text-align: left;
    margin-bottom: 0;
    padding: 0 24px;
  }
  
  p {
    margin: 0;
  }
`;

const LogoItem = styled.li`
  height: 218px;
  text-align: center;
  padding: 59px 65px;
  box-sizing: border-box;
  border-bottom: 1px solid #E1EFFF;
  border-right: 1px solid #E1EFFF;

  @media screen and (max-width: 970px) {
    text-align: left;
    padding: 56px 24px;
  }
`;

export const Logos = (props: {
  title: string;
  logos: Array<{
    title: string;
    link: string;
    altText: string;
  }>;
}): JSX.Element => {

  return (
    <Container>
      <Header><Markdown>{props.title}</Markdown></Header>
      <Grid>
        {
          props.logos.map((img, index) => (
            <LogoItem key={index}>
              <div style={{
                color: '#7b85a0',
                fontSize: 16,
                fontWeight: 500,
                marginBottom: 22
              }}>
                {img.title}
              </div>
              <img src={img.link} alt={img.altText}/>
            </LogoItem>
          ))
        }
      </Grid>
    </Container>
  );
};
