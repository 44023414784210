import React from 'react';
import Background from '../../images/impact-background.png';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {device} from '../../models/devices';

const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 198px;
  display: grid;
  grid-template-columns: 470px 571px;
  grid-column-gap: 130px;
  grid-row-gap: 24px;
  grid-template-areas:
    "header image"
    "text image";

  @media screen and ${device.tablet} {
    padding: 115px 24px 0;
    grid-template-columns: 1fr;
    grid-template-areas: "header" "text" "image";
  }
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.33;
  color: white;
  //margin-bottom: 24px;
  grid-area: header;
  margin: 0;
  align-self: end;

  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5em;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.78;
  color: #cfe7fc;
  letter-spacing: -0.1px;
  //margin-bottom: 58px;
  grid-area: text;
  align-self: start;

  @media screen and ${device.tablet} {
    font-size: 16px;
    line-height: 28px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 571px;
  margin: 0 auto;
  grid-area: image;
`;

export const TitleSection = (props: {
  title: string;
  text: string;
  image: string;
  alt: string;
}): JSX.Element => {
  return (
    <section style={{
      background: `url(${Background}) no-repeat bottom center`,
      backgroundSize: 'cover',
      paddingBottom: 152
    }}>
      <Wrapper>
        <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
        <Text><ReactMarkdown>{props.text}</ReactMarkdown></Text>
        <Image src={props.image} alt={props.alt}/>
      </Wrapper>
    </section>
  );
};
